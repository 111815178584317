import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/layout'

const CareersPage = () => {
  return (
    <Layout pageTitle="Careers" title="MRC Agrotech Ltd - Careers">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <StaticImage className="mb-3" src="../images/careers.jpg" width={1000} height={342} alt="Career" />
          <p>If you are a passionate self-starter, have a proven track-record of leadership skills and are blessed with unshakable ethics, then you are the right fit for MRC Agrotech and may be considered for a position in the team. Send in your CV along with a minimum of 3 references, for evaluation. If you are lucky, you might hear from the team soon. Please share your CV at <a href="mailto:careers@mrcagro.com">careers@mrcagro.com</a>.</p>
        </div>
      </div>
    </Layout>
  )
}

export default CareersPage
